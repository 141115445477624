import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
const RegularFont = 'Rubik-Light';

const EventsSection = styled.div`
  //border-top: 1px solid #eae7e7;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  padding: 22px 0 15px 0;
  float: left;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  > div {
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > svg {
        width: 25px;
        margin-right: 10px;
        height: 25px;
      }

      > span {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
`;

const SectionTitle = styled.span`
  
  position: relative;
  top: 18px;
  letter-spacing: 0px;
  //color: rgb(40,44,55,0.5);
  //font-family: ${BoldFont}
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
  margin-left: ${({marginLeft}) => marginLeft}
  display: ${({display}) => display};
  margin-right: auto;
  margin-left: auto;
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #0D4270;
  text-transform: capitalize;
  margin-top: 10px;
`;

const SectionOption = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #0D4270;
  mix-blend-mode: normal;  
  //font-family: ${RegularFont};
  min-width: 110px;
  text-align: left;
   position: relative;
   right: 61px;
   right: ${({ bgColor}) => bgColor ? '7px' : 'none'};
   bottom: ${({ bgColor}) => bgColor ? '10px' : 'none'};
`;

const ContactInfoSection = styled.div`
  border-top: 1px solid #eae7e7;
  width: 100%;
  padding: 22px 0 15px 0;
  float: left;
  text-align: center;

  > div {
    display: inline-block;
    text-align: right;
    margin-right: 5px;
    padding: 0px 10px;
    width: 100%;
    > div {
      font-size: 14px;
      line-height: 36px;
      color: rgb(153,153,153);
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        margin-left: 5px;
        color: #333;
        white-space: nowrap;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media(max-width: 1200px) {
          max-width: 100%;
        }
        @media(max-width: 700px) {
          max-width: 230px;
        }
      }
    }

    &:nth-child(3) {
      text-align: left;
      margin-right: 0;
      > div {
        color: black;
      }
    }

    &:last-child {
      margin-top: 12px;
      text-align: center;
      display: block;
      margin-right: 0;
    }
  }
`;

const StyledIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 9px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  line-height: unset !important;
  > i {
    color: white;
    font-size: 18px;
    margin-top: 5px;
  }

  i.fa.fa-slack {
    font-size: 20px;
  }

  i.fab.fa-skype {
    font-size: 21px;
  }
`;

const WellnessInitiativeSection = styled.div`
  padding: 0px 15px 0px 20px;
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 25px;
`;

const PersonalInfoSection = styled.div`
  width: 100%;
  padding: 30px 0 0px 0;
  float: left;
  text-align: center;

  > div {
    display: inline-block;
    margin-right: 5px;
    > div {
      font-size: 14px;
      line-height: 36px;
      color: rgb(153,153,153);
    }

    &:nth-child(3) {
      text-align: left;
      margin-right: 0;
      > div {
        color: black;
      }
    }

    &:last-child {
      display: block;
      text-align: center;
      margin-bottom: 10px;
      margin-right: 0;
      position: relative;
      // bottom: 25px;
      //right: 16px;
    }
  }
`;

const InitiativeSection = styled.div`
text-align: center;
margin-bottom: 10px;
	> div {
		// align-items: center;
		// flex-direction: column;
		//width: 100%;
    width: 100%;
    // left: 1349px;
    // top: 1295px;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 7px;
    display: flex;
    >div:first-child{
      width: 80%;
      display: flex;
      >div:first-child{
       >img{
          width: 25px;
          height:25px;
          margin: 10px;
       }
      }
      >div:last-child{
       >div{
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0D4270;
        
       }
       margin: auto 0px;
       font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 21px;
align-items: center;

color: #0D4270;
      }
    }
    >div:last-child{
      width: 20%;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
      color: #0D4270;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
    }

}
`;

const InitiativeTitle = styled.span`
  //font-size: 14px;
  letter-spacing: 0px;
  color: ${({ color }) => color ? color : 'rgb(51,51,51)'};
  //font-family: ${MediumFont};
	//color: #282C37;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #0D4270;
  
`;
const InitiativeTest = styled.span`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #0D4270;
position: relative;
top: 10px;
`;


const ImageAndProfile = styled.div`
  float: left;
  display: flex;
  padding-left: 21px;
  //width: 100%;
  text-align: center;
  // margin-top: 25px;
  .employeeImage {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    background:  #C4C4C4;
    position: relative;
    //overflow: hidden;
    margin: 0 auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > img {
      margin-top: 0px;
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }
  }
`;

const UploadContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  > label {
  width: 210px;
  height: 44px;
  border-radius: 22px;
  background-color: #159fc9;
  border: none;
  position: relative;
  font-size: 12px;
  color: rgb(255,255,255);
  font-family: ${BoldFont};
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding-top: 5px;
  cursor: pointer;
  margin-top: 10px;
  //margin-left: 10px;
  opacity: 1; 
  >input {
    display: none;
  }

  //   @media (max-width: 1150px) {
  //     margin: 0
  //   }
  //   @media (max-width: 600px) {
  //     width: 65%;
  //     max-width: none;
  //   }
  //    
  // }
  //
  // @media (max-width: 1150px) {
  //   float: left;
  //   width: 50%;
  // }
  //
  // @media (max-width: 600px) {
  //   float: none;
  //   width:  100%;
  // }
`;

const WellnessInitiativeContainer = styled.div`
  width: 100%;
  // border-top: 2px solid rgba(156, 156, 156, 0.1);
  position: relative;
  // top: 19px;
`;

const WellnessInitiativeRow = styled.div`
  display: flex;
  padding: 15px 0;
  // border-bottom: 2px solid #F6F6F8;
  > div {
    width: 100%;
    float: left
    .fixedIcon {
      >svg {
        max-width: 23px;
        width: 100%;
        overflow: hidden;
        height: 100%;
        float: left;
      }
      >img {
        max-width: 50px;
        width: 100%;
        overflow: hidden;
        height: 50px;
        float: left
      }
    }
    .secondGrid {
      padding-right: 5px;
      padding-left: 17px;
      word-break: break-all;
      float: left;
      width: calc(100% - 80px);
    }
    .thirdGrid {
      font-family: ${BoldFont};
      font-size: 15px;
      float: right;
    }
  }
    
    > div {
      > div {
        font-size: 12px;
        color: rgb(153,153,153);
      }
    }
  }

`;

const TickWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  //border:  ${({ rightTick}) => rightTick ? '1.4px solid #71A147' : '1.4px solid #EC7125'};
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 22px;
    height: 22px;
  }
`;

const WellnessPoint = styled.div`
  //border: 0.768px solid #E9E9E9;
  width: 76px;
  height: 20px;
  border-radius: 3.072px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4798D9;
  //font-family: ${MediumFont};
  //font-size: 10px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
justify-content: flex-start;
  & > span {
   color: #4798D9;
  }
`;

const InformationContainer = styled.div`
  width: 100%;
  text-align: left;
  //padding: 10px 12px;
  padding: 0px 12px;
  float: left;
  & > p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #0D4270;
    mix-blend-mode: normal;
     float: left;
    //max-width: 110px
    //width: 44%;
    position: relative;
    left: 6px;
    &:nth-child(2) {
      //font-family: ${BoldFont}
      //font-family: Rubic
      //color: #283C37;
      font-size: 15px;
      float: left;
      margin-left: 5px;
      //width: calc(65% - 5px);
      //word-break: break-all;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #9C9C9C;
    }
  }
 
`;
const PageData = styled.p`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 21px;
color: #9c9c9c;
mix-blend-mode: normal;
 float: left;
//max-width: 110px
// width: 35%
`;
const Buttoncontainer = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  padding: 10px;
  
  @media (max-width: 1150px) {
    width: 50%
    float: left;
  }
  @media (max-width: 600px){
    float:left;
    width:100%;
  }
`;

const ButtonHolder = styled.div`
  float: left;
  width: 65%
`;

const DeleteButton = styled.div`
  position: relative;
  font-size: 12px;
  cursor: pointer;
  color: #93959B;
  width: calc(35% - 5px);
  font-family: ${BoldFont};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  margin-left 5px;
  margin-top: 3px;
  float: left;
  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  @media (max-width: 600px) {
    margin-top: 10px;
    float: ${({float}) => float && float };
    margin-bottom: ${({marginBottom}) => marginBottom && marginBottom };
  }
`;

const ShareIconContainer = styled.div`
  width: 100%;
  padding: 10px 30px;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > a {
    display: block;
    float: left;
    
    > img {
      width: 20px;
    }
  }
`;
const WellnessProgramContainer = styled.div`
  float: left;
  width: 100%;
  > div {
    width: 100%;
    padding-bottom: 15px;
    float: left;
    .fixedIcon {
      float: left;
      >svg {
        width: 40px;
        height: 40px;
      }
      >img {
        width: 40px;
        height: 40px;
        
      }
    }
    .secondGrid {
      padding-right: 5px;
      padding-left: 5px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      float:left;
      width: 175px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden
    }
    .thirdGrid {
      font-family: ${BoldFont};
      font-size: 20px;
      color: #282C37;
      float:right;
    }
  }
`;
const HeaderContainer = styled.div`
  float: left;
  padding-bottom: 15px;
  width: 100%;
`;

const RecognitionContainer = styled(WellnessInitiativeSection)`
padding: 0px 0px 5px 0px;

  > div {
      width: 100%;
      // display: grid;
      justify-content: space-between;
   
			// gap: 5px;
      grid-template-columns: repeat(3, 32%);
      // grid-template-rows: repeat(${({length}) => length}, 85px);
      grid-auto-flow: row;

      @media(max-width: 767px) {
				gap: 15px 5%
				grid-template-columns: repeat(3, 30%);
        grid-template-rows: repeat(${({length}) => length}, 95px);
      }

    }

	// @media(max-width: 1251px) and (min-width: 1200px) {
	// 	padding: 25px 5px 30px;

	// 	> div {
	// 		grid-template-columns: repeat(3, 33%);
	// 		gap: 5px 1px;
	// 	}

	// }

  // @media(max-width: 1199px) and (min-width: 768px) {
  //   padding: 25px 4px 30px;

  //   > div {
  //     grid-template-columns: repeat(3, 33%);
  //     grid-template-rows: repeat(${({length}) => length}, 95px);
  //     gap: 5px 1px;
  //   }
	// }

`;

const EditButton = styled.div`
  float: right;
  padding: 1px 0px 15px 15px;
  cursor: pointer;
  //background-color: #159fc9;
  //border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: Open-Sans-Semibold;
  width: 50%;
  display: flex;
  justify-content: flex-end;
   > img {
    height: 32px;
    width: 32px;
    }
`;

const ContactData = styled.div`
  float: left;
  display: flex;
  //padding-left: 21px;
  //width: 100%;
  text-align: center;
  //margin-top: 25px;
  .employeeImage {
    width: 122px;
    height: 122px;
    border-radius: 6px;
    background:  #C4C4C4;
    position: relative;
    //overflow: hidden;
    margin: 0 auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    > img {
      margin-top: 0px;
      width: 122px;
      height: 122px;
    }
  }
`;
const TextContainer = styled.div`
  width: 100%;
  text-align: left;
  //padding: 10px 12px;
  padding: 0px 12px;
  float: left;
  border-top: 2px solid rgba(156, 156, 156, 0.1);
  display: flex;
  justify-content: center;
  padding-top: 25px;
  & > p {
    font-family: Rubik-Medium;      
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #0D4270;
    mix-blend-mode: normal;
     float: left;
     margin-bottom: 15px;
    
    &:nth-child(2) {
      font-family: ${BoldFont}
      color: #283C37;
      font-size: 13px;
      float: left;
      margin-left: 5px;
      width: calc(65% - 5px);
      word-break: break-all;
    }
  }
`;

const RewardContainer = styled.div`
  width: 100%;
  text-align: left;
  //padding: 10px 12px;
  padding: 0px 15px 0px 15px;
  padding: ${({padding, paddingNew}) => padding ? '0px' : paddingNew ? paddingNew : '0px 15px 0px 15px' };
  float: left;
  border-top: 2px solid rgba(156, 156, 156, 0.1);
  border-bottom: 2px solid rgba(156, 156, 156, 0.1);
  // top: 25px;
  // position: relative;
  & > p {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;
    font-family: Rubik-Medium;      
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #0D4270;
    mix-blend-mode: normal;
    text-align: center;
     float: left;
    //max-width: 110px
    // width: 55%
    // position: relative;
    // top: 5px;
    // left: 150px;
    &:nth-child(2) {
      font-family: ${BoldFont}
      color: #283C37;
      font-size: 13px;
      float: left;
      margin-left: 5px;
      width: calc(65% - 5px);
      word-break: break-all;
    }
  }
`;

const WellnessChampionTitle = styled.div`
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 18px;
background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
border-radius: 6px 6px 0px 0px;
text-align: center;
height: 40px;
color: white;
padding: 10px;
margin-bottom: 9px;
`;

const BiometricButton = styled.div`
width: 100%;
display: flex;
justify-content: center;
margin-bottom: 15px;
margin-top: -15px;
padding: 0px 15px;
>button{
  height: 40px;
  width: 100%;
  background: #159fc9;
  color: #fff;
  font-family: "Rubik-Medium";
  text-align: center;
  cursor: pointer;
  opacity: 1;
  font-size: 16px;
  border: none;
  border-radius: 3px;
}
`;



export { EventsSection, SectionTitle, SectionOption, ContactInfoSection, StyledIcon, WellnessInitiativeSection, PersonalInfoSection,
  InitiativeSection, InitiativeTitle, ImageAndProfile, UploadContainer, WellnessInitiativeContainer, WellnessInitiativeRow, TickWrapper,
  WellnessPoint, InformationContainer, Buttoncontainer, ButtonHolder, DeleteButton, ShareIconContainer,WellnessProgramContainer,HeaderContainer,
  RecognitionContainer, EditButton,ContactData,TextContainer,RewardContainer,InitiativeTest,PageData, WellnessChampionTitle, BiometricButton};